import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import PrevButton from './prev-button'
import NextButton from './next-button'

export type MiniCarouselProps = {
  slides?: React.ReactNode[]
  id: string
}

const MiniCarousel = ({ slides, id }: MiniCarouselProps) => {
  const [viewportRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    dragFree: true,
    containScroll: 'trimSnaps',
    inViewThreshold: 1,
    align: 'center',
  })
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return

    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) return

    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  return (
    <div
      key={id}
      className="relative cursor-grab overflow-hidden pb-7 active:cursor-grabbing"
      id="embla__viewport"
      ref={viewportRef}
    >
      <div className="mx-auto flex max-w-min gap-x-6 md:gap-x-10" id="embla__container">
        {slides &&
          slides.map((slide, i) => (
            <div className="relative" id="embla__slide" key={i}>
              <div className="relative flex min-h-full flex-col pt-4" id="embla__slide__inner">
                {slide}
              </div>
            </div>
          ))}
      </div>

      {scrollSnaps.length > 1 && (
        <div className="relative mt-10 -ml-6 max-w-36 max-md:hidden" id="embla__dots">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      )}
    </div>
  )
}

export default MiniCarousel
