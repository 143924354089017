import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { cn } from '../../../helpers/tailwind'
import { ButtonProps } from './next-button'

export const PrevButton = ({ enabled, onClick }: ButtonProps) => (
  <button
    id="embla__button embla__button--prev"
    className="align-center absolute top-1/2 left-[27px] z-1 h-[30px] w-[30px] -translate-y-1/2 cursor-pointer touch-manipulation justify-center border-none bg-transparent fill-selphBlue-700 outline-hidden disabled:cursor-default disabled:opacity-20"
    onClick={onClick}
    disabled={!enabled}
    aria-label="Previous slide"
  >
    <span
      className={cn(
        enabled
          ? 'transition duration-300 hover:border-selphAmber-500 hover:bg-selphAmber-500 hover:text-selphWhite-500'
          : '',
        'flex h-10 w-10 items-center justify-center rounded-full border border-selphBlue-700',
      )}
    >
      <ChevronLeftIcon className="h-6 w-6" />
    </span>
  </button>
)

export default PrevButton
