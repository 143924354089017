import { now, toIsoString } from '../../../helpers/date'
import BlogCard from '../../learn/blog-card'
import MiniCarousel from '../mini-carousel/mini-carousel'
import { Article, BlogCardFragment } from '@/gql'

const ArticleFeed = ({ blogs }: { blogs: BlogCardFragment[] | Article[] }) => (
  <MiniCarousel
    slides={blogs.map((blog, index) => (
      <BlogCard blog={blog} key={blog.title} color={getColor(index)} />
    ))}
    id={toIsoString(now())}
  />
)

export default ArticleFeed

const colors = [
  { card: 'bg-selphGreen-500 group-hover:bg-selphGreen-600', category: 'bg-selphGreen-400' },
  { card: 'bg-selphOrange-500 group-hover:bg-selphOrange-600', category: 'bg-selphOrange-400' },
  { card: 'bg-selphTeal-500 group-hover:bg-selphTeal-600', category: 'bg-selphTeal-400' },
  { card: 'bg-selphAmber-500 group-hover:bg-selphAmber-600', category: 'bg-selphAmber-400' },
  { card: 'bg-selphRed-500 group-hover:bg-selphRed-600', category: 'bg-selphRed-400' },
]

export const getColor = (index: number) => colors[index % colors.length]
