import blurImageJson from '../../data/markdown-blur-images.json'
import { Article, BlogCardFragment } from '../../gql'
import * as UI from '@/ui'

export type BlogCardProps = {
  blog: BlogCardFragment | Article
  color: { card: string; category: string }
}

export const BlogCard = ({ blog, color }: BlogCardProps) => {
  return (
    <UI.Link type="basic" to={['blogPost', { path: blog.path }]}>
      <div
        className={`group flex h-full min-h-[420px] w-[280px] flex-col items-stretch overflow-clip rounded-xl hover:shadow-xl min-[340px]:w-xs`}
      >
        <div className="relative flex h-[256px] items-center justify-center overflow-clip rounded-t-xl">
          <UI.Image
            className="object-cover object-center transition-transform duration-300 group-hover:scale-110"
            blurImageJson={blurImageJson}
            blurImageJsonFolder="/images/blogs/"
            fill
            sizes="(nin-width: 340px) 320px, 280px"
            src={`/images${blog.hero.src}`}
            alt={blog.title}
          />

          <div className="absolute inset-0 bg-selphGreen-500 opacity-15" />
        </div>

        <div className={`flex grow flex-col p-6 ${color.card}`}>
          <span className="grow text-xl leading-tight text-selphWhite-500">{blog.title}</span>

          <div className="flex items-center justify-between font-normal text-selphWhite-500">
            <UI.Badge size="medium" color="none" className={`${color.category} rounded-md capitalize`}>
              {blog.categories[0]}
            </UI.Badge>

            <p>{`${blog.readingTimeMinutes}-min read`}</p>
          </div>
        </div>
      </div>
    </UI.Link>
  )
}

export default BlogCard
